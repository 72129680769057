/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormHelperText,
  IconButton,
} from '@mui/material';
import {
  getPhoenixDateTime,
  strictValidString,
} from '../../utils/common-utils';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MobileDatePicker as MobileDate } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ClockIcon from '@mui/icons-material/AccessTime';
import Dialog from '../dialog';
import WarningAmberIcon from '@mui/icons-material/Warning';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { ClickAwayListener } from '@mui/material';
import moment from 'moment';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { toString, upperCase } from 'lodash';

const useStyles = makeStyles((theme) => ({
  withoutLabel: {
    marginTop: theme.spacing(2),
    backgroundColor: '#FAFAFA',
  },
  textField: {
    width: '100%',
  },
  input: {
    fontSize: 14,
  },
  helperText: {
    margin: `${theme.spacing(0.2, 0, 0, 0)} !important`,
    backgroundColor: '#F3F3F3',
    color: theme.palette.error.main,
  },
  disabledCheckbox: {
    backgroundColor: '#F3F3F3',
  },
  header: {
    marginTop: theme.spacing(0.5),
    fontSize: 18,
  },
  button: {
    width: 200,
    textTransform: 'capitalize',
  },
}));
const defaultPropGetter = () => ({});

export const highlightCurrentDate = () => {
  setTimeout(() => {
    const element = document.querySelector(
      'div div.MuiPickersCalendarHeader-label',
    );
    const currentDate = moment(getPhoenixDateTime());
    if (currentDate.format('MMMM yyyy') === element?.innerHTML) {
      const date = currentDate.format('D');
      const buttons = document.querySelectorAll('.MuiPickersDay-root');
      const buttonArray = Array.from(buttons);
      buttonArray.map((button) => {
        if (button.innerText === toString(date)) {
          const classAr = Array.from(button.classList);
          button.style.setProperty(
            'border',
            classAr.find((cls) => cls === 'Mui-selected')
              ? 'none'
              : '1px solid rgba(0, 0, 0, 0.6)',
          );
          button.classList.add('MuiPickersDay-today');
        }
        button.blur();
        return false;
      });
    }
  }, 100);
};

export default function MdDatePicker({
  placeholder,
  required,
  onChange,
  onBlur,
  name,
  input,
  value,
  onOpen,
  renderValue,
  errorText,
  type,
  otherProps,
  disabled,
  maxTime,
  minTime,
  minDateTime,
  minDate,
  disableFuture,
  maxDate,
  id,
  disablePast = true,
  maxDateTime,
  closeOnSelect = true,
  compareTime = true,
  clearText = false,
  onSave = defaultPropGetter,
  readOnly = false,
  inputFormat = 'MM/dd/yyyy',
  format = '',
  toolbarPlaceholder = 'MM/DD/YYYY',
  onError = defaultPropGetter,
  pickerType,
  pickerActions = ['', 'cancel'],
}) {
  const classes = useStyles();
  const [datetime, setdatetime] = React.useState(value || null);
  const [isDeleteDialog, setIsDeleteDialog] = React.useState(false);
  const [time, setTime] = React.useState('');
  const [focused, setFocued] = React.useState(false);
  const [dateFocused, setdateFocued] = React.useState(false);
  const [willcalldateFocused, setWIllCalldateFocued] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [change, setOnChange] = React.useState(false);
  const currIsValid = React.useRef(false);
  const anchorRef = React.useRef(null);
  React.useEffect(() => {
    if (!datetime && required) {
      setdatetime('');
    } else if (!datetime) {
      setdatetime(null);
    }
  }, [required]);
  React.useEffect(() => {
    if (value === null || value === undefined) {
      setdatetime(null);
    }
  }, [value]);
  // React.useEffect(() => {
  //   if (parseISO(datetime) === 'Invalid Date' || datetime === null) {
  //     setdatetime(null);
  //   }
  // }, [datetime && required]);
  const deleteToggle = () => {
    setIsDeleteDialog(!currIsValid.current && !isDeleteDialog);
  };
  const deleteDialog = (id) => {
    return (
      <Dialog
        appBarColor="error"
        title={'Warning !'}
        closeIcon={false}
        fullScreen={false}
        maxWidth={'sm'}
        sxAppBar={{ justifyContent: 'center', alignItems: 'center' }}
        sxTitle={{ fontSize: 24 }}
        fullWidth
        isOpen={isDeleteDialog}
        handleClose={() => {
          deleteToggle();
        }}
      >
        <DialogContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <WarningAmberIcon
            color="error"
            sx={{ height: 60, width: 60, mb: 3 }}
          />
          <DialogContentText
            className={classes.header}
            id="alert-dialog-slide-description"
          >
            {type === 'datepicker'
              ? 'Pick-up date set in the past'
              : 'Pick-up Time set in the past'}
            ,
          </DialogContentText>
          <DialogContentText
            className={classes.header}
            id="alert-dialog-slide-description"
          >
            are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'space-around', display: 'flex', mt: 2 }}
        >
          <Button
            className={classes.button}
            color="success"
            variant="outlined"
            onClick={() => {
              deleteToggle();
              setdatetime(time);
              onChange(time);
            }}
          >
            Yes, Continue
          </Button>
          <Button
            className={classes.button}
            color="error"
            variant="outlined"
            onClick={() => {
              deleteToggle();
              setFocued(true);
              setdatetime(null);
              onChange(null);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const handleDateChange = (v) => {
    if (!compareTime && !change) {
      if (openPicker) {
        // setTime(v);
        setdatetime(v);
        onChange(v);
        onSave(v);
      } else {
        if (upperCase(v) !== 'INVALID DATE') {
          const pheonixTime = getPhoenixDateTime();
          const parsedSelectedTime =
            type === 'datepicker'
              ? Date.parse(moment(v).format('MM/DD/YYYY 23:59:59'))
              : Date.parse(v);
          if (parsedSelectedTime < pheonixTime) {
            currIsValid.current = false;
            setTimeout(() => {
              setTime(v);
              deleteToggle();
            }, 2000);
          } else {
            setdatetime(v);
            currIsValid.current = true;
            onChange(v);
            onSave(v);
            setTime(v);
          }
        }
      }
    }
    if (v === null) {
      setdatetime(null);
    }
  };
  const handleAccDateChange = (v) => {
    if (!compareTime) {
      if (upperCase(v) !== 'INVALID DATE') {
        setdatetime(v);
        onChange(v);
        onSave(v);
        setTime(v);
      }
    }
    if (v === null) {
      setdatetime(null);
    }
  };

  const handleWillCallChange = (v) => {
    if (!compareTime && !change) {
      if (openPicker) {
        setTime(v);
        setdatetime(v);
        onChange(v);
        onSave(v);
      } else {
        if (upperCase(v) !== 'INVALID DATE') {
          const pheonixTime = getPhoenixDateTime();
          const parsedSelectedTime = Date.parse(
            moment(v).format('MM/DD/YYYY 23:59:59'),
          );
          if (parsedSelectedTime < pheonixTime) {
            currIsValid.current = false;
            setTimeout(() => {
              deleteToggle();
              setTime(v);
            }, 2000);
          } else {
            currIsValid.current = true;
            setdatetime(v);
            onChange(v);
            onSave(v);
          }
        }
      }
    }
    if (v === null) {
      setdatetime(null);
    }
  };
  React.useEffect(() => {
    if (
      errorText === 'Please Enter Valid date' ||
      errorText === 'End Time Date Should Be Greater Then Start Time Date' ||
      errorText === 'Working Hours Should be greater then 0 Hours' ||
      errorText === 'Unit should be less then 24 hours'
    ) {
      setFocued(true);
    }
  }, [errorText]);
  const handleUnitChange = (v) => {
    if (!compareTime) {
    } else {
      if (upperCase(v) !== 'INVALID DATE') {
        if (!change) {
          onChange(v);
          onSave(v);
          setdatetime(v);
        } else {
          setdatetime(v);
          onChange(v);
          onSave(v);
        }
      }
    }
    if (v === null) {
      setdatetime(null);
    }
  };

  const handleChange = (v) => {
    if (!compareTime) {
    } else {
      setdateFocued(true);
      setdatetime(v);
      onChange(v);
      onSave(v);
    }
    if (v === null) {
      setdatetime(null);
    }
  };

  const onSubmit = (v) => {
    const pheonixTime = getPhoenixDateTime();
    if (!compareTime) {
      const parsedSelectedTime =
        type === 'datepicker'
          ? Date.parse(moment(v).format('MM/DD/YYYY 23:59:59'))
          : Date.parse(v);
      if (parsedSelectedTime < pheonixTime) {
        deleteToggle();
        setTime(v);
      } else {
        setdatetime(v);
        onChange(v);
        onSave(v);
      }
    }
  };
  const onlyDateSubmit = (v) => {
    if (!compareTime) {
      setdatetime(v);
      onChange(v);
      onSave(v);
    }
  };

  if (type === 'onlydate-small') {
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDate
            sx={{
              '& .MuiSvgIcon-fontSizeMedium': { display: 'none' },
            }}
            disableHighlightToday
            DialogProps={{
              onClose: () => {},
            }}
            // disablePast
            closeOnSelect={true}
            showTodayButton={false}
            allowSameDateSelection={true}
            // clearText={true}
            allowKeyboardControl={true}
            showToolbar={false}
            toolbarTitle="Select Date and Time"
            format="MM/dd/yy"
            label={placeholder}
            onMonthChange={highlightCurrentDate}
            onYearChange={highlightCurrentDate}
            onViewChange={highlightCurrentDate}
            value={datetime}
            onChange={(v) => {
              handleChange(v);
            }}
            onError={onError}
            minutesStep={5}
            onAccept={onlyDateSubmit}
            disabled={disabled}
            disableFuture={disableFuture}
            maxDateTime={maxDateTime}
            maxDate={maxDate}
            minDateTime={minDateTime || null}
            minTime={minTime}
            minDate={minDate}
            className={clsx(classes.withoutLabel)}
            InputProps={{ classes: { disabled: classes.disabledCheckbox } }}
            maxTime={maxTime}
            slotProps={{
              actionBar: { actions: ['cancel'] },
              toolbar: {
                toolbarPlaceholder: '',
                hidden: true,
              },
            }}
            slots={{
              textField: (textFieldProps) => (
                <TextField
                  onBlur={onBlur}
                  required={required}
                  size="small"
                  id={id}
                  error={
                    strictValidString(errorText) ||
                    (required &&
                      !disabled &&
                      !strictValidString(textFieldProps.inputProps.value))
                  }
                  className={classes.input}
                  {...textFieldProps}
                  sx={{ mr: 2, backgroundColor: '#FAFAFA' }}
                />
              ),
            }}
            {...otherProps}
          />
          {strictValidString(errorText) && (
            <FormHelperText
              className={classes.helperText}
              id="component-helper-text"
            >
              {errorText}
            </FormHelperText>
          )}
        </LocalizationProvider>
        {deleteDialog()}
      </>
    );
  }
  if (type === 'updateDatePicker') {
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <span ref={anchorRef}>
            <DesktopDateTimePicker
              label={placeholder}
              maxDate={maxDate}
              minDateTime={minDateTime || null}
              minTime={minTime}
              open={openPicker}
              maxTime={maxTime}
              maxDateTime={maxDateTime || null}
              disableHighlightToday
              onMonthChange={highlightCurrentDate}
              onYearChange={highlightCurrentDate}
              onViewChange={highlightCurrentDate}
              onChange={(v) => {
                highlightCurrentDate();
                setFocued(true);
                if (v === null) {
                  setdatetime(null);
                }
                if (pickerType === 'trip') {
                  handleDateChange(v);
                } else if (pickerType === 'unit') {
                  handleUnitChange(v);
                } else if (pickerType === 'accdatetime') {
                  handleAccDateChange(v);
                } else {
                  handleChange(v);
                }
              }}
              onClose={() => {
                setOpenPicker(false);
                setFocued(true);
                setOnChange(false);
              }}
              disableFuture={disableFuture}
              disabled={disabled}
              // views={['month', 'day', 'year', 'hours', 'minutes']}
              ampm={false}
              localeText={{ toolbarTitle: 'Select Date and Time' }}
              closeOnSelect={false}
              onError={onError}
              onAccept={onSubmit}
              value={datetime}
              slotProps={{
                openPickerButton: {
                  onClick: () => {
                    highlightCurrentDate();
                    setOpenPicker(true);
                  },
                },
                actionBar: {
                  actions: ['cancel', 'accept'],
                },
                toolbar: {
                  toolbarPlaceholder: '',
                  hidden: false,
                },
                textField: {
                  required: required,
                  onBlur: onBlur,
                  value:
                    datetime && disabled
                      ? datetime
                      : disabled
                      ? null
                      : datetime,
                  onChange: (e) => {
                    setFocued(true);
                    if (e === null) {
                      setdatetime(null);
                    }
                    if (pickerType === 'trip') {
                      handleDateChange(e);
                    } else if (pickerType === 'unit') {
                      handleUnitChange(e);
                    } else if (pickerType === 'accdatetime') {
                      handleAccDateChange(e);
                    } else {
                      handleChange(e);
                    }
                  },
                },
              }}
              format="MM/dd/yy HH:mm"
              sx={{
                backgroundColor: !disabled && 'white',
                width: '100%',
                mt: 2,
              }}
            />
          </span>
          {strictValidString(errorText) && required && !disabled && focused ? (
            <FormHelperText
              className={classes.helperText}
              id="component-helper-text"
            >
              {errorText}
            </FormHelperText>
          ) : strictValidString(errorText) &&
            !required &&
            !disabled &&
            focused &&
            pickerType === 'accdatetime' ? (
            <FormHelperText
              className={classes.helperText}
              id="component-helper-text"
            >
              {errorText}
            </FormHelperText>
          ) : (
            ''
          )}
          {deleteDialog()}
        </LocalizationProvider>
      </>
    );
  }

  if (type === 'datepicker') {
    return (
      <ClickAwayListener>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <span ref={anchorRef}>
            <DesktopDatePicker
              // disablePast={disablePast}
              sx={{
                mt: 2,
                width: '100%',
                backgroundColor: !disabled && 'white',
              }}
              format="MM/dd/yy"
              value={datetime}
              open={openPicker}
              showToolbar={false}
              // clearable
              allowSameDateSelection={true}
              onClose={() => {
                setOpenPicker(false);
                setWIllCalldateFocued(true);
              }}
              onOpen={() => {
                setOpenPicker(true);
              }}
              disableOpenPicker={disabled}
              onError={onError}
              label={placeholder}
              onChange={(e) => {
                setWIllCalldateFocued(true);
                if (pickerType === 'acc_user') {
                  handleChange(e);
                } else {
                  handleWillCallChange(e);
                }
              }}
              disableHighlightToday
              onAccept={onSubmit}
              disabled={disabled}
              onMonthChange={highlightCurrentDate}
              onYearChange={highlightCurrentDate}
              onViewChange={highlightCurrentDate}
              disableFuture={disableFuture}
              minDateTime={minDateTime || null}
              minDate={minDate || null}
              maxDate={maxDate}
              error={
                strictValidString(errorText) ||
                (required && !disabled && !strictValidString(datetime))
              }
              slotProps={{
                actionBar: {
                  actions: ['clear'],
                  onClick: () => {
                    setWIllCalldateFocued(true);
                    setdatetime(null);
                  },
                },
                toolbar: {
                  toolbarPlaceholder: '',
                  hidden: true,
                },
                textField: {
                  placeholder: ' ',
                  disabled: disabled,
                  value: disabled ? null : datetime,
                  onBlur: () => onBlur,
                  onClick: () => {
                    highlightCurrentDate();
                  },
                  onChange: (v) => {
                    setWIllCalldateFocued(true);
                    highlightCurrentDate();
                    if (v === null) {
                      setdatetime(null);
                    } else if (pickerType === 'acc_user') {
                      handleChange(v);
                    } else {
                      handleWillCallChange(v);
                    }
                  },
                },
                popper: {
                  id: id,
                  anchorEl: anchorRef.current,
                  open: openPicker,
                  transition: true,
                  style: { paddingTop: 55 },
                },
              }}
              {...otherProps}
            />
          </span>
          {strictValidString(errorText) &&
            required &&
            !disabled &&
            willcalldateFocused && (
              <FormHelperText
                className={classes.helperText}
                id="component-helper-text"
              >
                {errorText}
              </FormHelperText>
            )}
          {deleteDialog()}
        </LocalizationProvider>
      </ClickAwayListener>
    );
  }
  if (type === 'date') {
    return (
      <ClickAwayListener>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <span ref={anchorRef}>
            <DesktopDatePicker
              disablePast={disablePast}
              todayText="now"
              sx={{
                mt: 2,
                width: '100%',
                backgroundColor: !disabled && 'white',
              }}
              showTodayButton
              format={!format ? 'MM/dd/yyyy' : format}
              toolbarPlaceholder={toolbarPlaceholder}
              value={datetime}
              open={openPicker}
              showToolbar={false}
              // clearable
              allowSameDateSelection={true}
              onClose={() => {
                setOpenPicker(false);
                setdateFocued(true);
              }}
              onOpen={() => setOpenPicker(true)}
              disableOpenPicker={disabled}
              onError={onError}
              label={placeholder}
              onChange={handleChange}
              disableHighlightToday
              disabled={disabled}
              onMonthChange={highlightCurrentDate}
              onYearChange={highlightCurrentDate}
              onViewChange={highlightCurrentDate}
              disableFuture={disableFuture}
              minDateTime={minDateTime || null}
              minDate={minDate || null}
              maxDate={maxDate}
              error={
                strictValidString(errorText) ||
                (required && !disabled && !strictValidString(datetime))
              }
              slotProps={{
                actionBar: {
                  actions: ['clear'],
                },
                toolbar: {
                  toolbarPlaceholder: '',
                  hidden: true,
                },
                textField: {
                  placeholder: ' ',
                  disabled: disabled,
                  value: disabled ? null : datetime,
                  onBlur: () => onBlur,
                  onClick: () => {
                    highlightCurrentDate();
                  },
                },
                popper: {
                  id: id,
                  anchorEl: anchorRef.current,
                  open: openPicker,
                  transition: true,
                  style: { paddingTop: 55 },
                },
              }}
              slots={
                {
                  // textField: (textFieldProps) => (
                  //   <>
                  //     <TextField
                  //       // className={clsx(classes.withoutLabel, classes.textField)}
                  //       id={id}
                  //       variant="outlined"
                  //       onBlur={onBlur}
                  //       {...textFieldProps}
                  //       value={
                  //         textFieldProps.value !== 'MM/DD/YYYY'
                  //           ? textFieldProps.value
                  //           : ''
                  //       }
                  //       sx={{
                  //         mr: 2,
                  //         backgroundColor: !disabled && '#FAFAFA',
                  //         width: '100%',
                  //         mt: 2,
                  //         '& input': {
                  //           readOnly: true,
                  //           cursor: !disabled && 'pointer',
                  //         },
                  //       }}
                  //       required={required}
                  //       error={
                  //         strictValidString(errorText) ||
                  //         (required &&
                  //           !disabled &&
                  //           textFieldProps.value === 'MM/DD/YYYY')
                  //       }
                  //     />
                  //   </>
                  // ),
                }
              }
              {...otherProps}
            />
          </span>
          {strictValidString(errorText) &&
            required &&
            !disabled &&
            dateFocused && (
              <FormHelperText
                className={classes.helperText}
                id="component-helper-text"
              >
                {errorText}
              </FormHelperText>
            )}
        </LocalizationProvider>
      </ClickAwayListener>
    );
  }
  if (type === 'onlyIcon') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          disablePast={disablePast}
          todayText={'Now'}
          showTodayButton
          inputFormat={inputFormat}
          toolbarPlaceholder={toolbarPlaceholder}
          value={datetime}
          showToolbar={false}
          clearable
          allowSameDateSelection={true}
          label={placeholder}
          onChange={() => {}}
          disabled={disabled}
          disableFuture={disableFuture}
          minDateTime={minDateTime || null}
          minDate={minDate || null}
          DialogProps={{
            onClose: () => {},
          }}
          onError={onError}
          maxDate={maxDate}
          error={
            strictValidString(errorText) ||
            (required && !disabled && !strictValidString(datetime))
          }
          onOpen={() => setOpenPicker(!openPicker)}
          open={openPicker}
          cancelText={false}
          clearText="Cancel"
          onAccept={handleChange}
          onClose={() => setOpenPicker(false)}
          InputProps={{ classes: { disabled: classes.disabledCheckbox } }}
          renderInput={(params) => (
            <IconButton
              disabled={disabled}
              onClick={() => setOpenPicker(!openPicker)}
              {...params}
            >
              <CalendarMonthIcon {...params} />
            </IconButton>
          )}
          {...otherProps}
        />
        {strictValidString(errorText) && (
          <FormHelperText
            className={classes.helperText}
            id="component-helper-text"
          >
            {errorText}
          </FormHelperText>
        )}
      </LocalizationProvider>
    );
  }
  if (type === 'onlyIcon-date') {
    return (
      <div className={classes.margin}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <span ref={anchorRef}>
            <DesktopDatePicker
              disablePast={disablePast}
              todayText="now"
              sx={{
                mt: 2,
                width: '100%',
                backgroundColor: !disabled && 'white',
              }}
              showTodayButton
              format="MM/dd/yyyy"
              toolbarPlaceholder={toolbarPlaceholder}
              value={datetime}
              open={openPicker}
              showToolbar={false}
              // clearable
              allowSameDateSelection={true}
              onClose={() => {
                setOpenPicker(false);
                setdateFocued(true);
              }}
              onOpen={() => setOpenPicker(true)}
              disableOpenPicker={disabled}
              onError={onError}
              label={placeholder}
              onChange={handleChange}
              disableHighlightToday
              disabled={disabled}
              onMonthChange={highlightCurrentDate}
              onYearChange={highlightCurrentDate}
              onViewChange={highlightCurrentDate}
              disableFuture={disableFuture}
              minDateTime={minDateTime || null}
              minDate={minDate || null}
              maxDate={maxDate}
              error={
                strictValidString(errorText) ||
                (required && !disabled && !strictValidString(datetime))
              }
              slotProps={{
                actionBar: {
                  actions: ['cancel'],
                },
                toolbar: {
                  toolbarPlaceholder: '',
                  hidden: true,
                },
                textField: {
                  placeholder: ' ',
                  disabled: disabled,
                  value: disabled ? null : datetime,
                  onBlur: () => onBlur,
                  onClick: () => {
                    highlightCurrentDate();
                  },
                },
                popper: {
                  id: id,
                  anchorEl: anchorRef.current,
                  open: openPicker,
                  transition: true,
                  style: { paddingTop: 55 },
                },
              }}
              slots={{
                textField: (textFieldProps) => (
                  <IconButton
                    disabled={textFieldProps.disabled}
                    onClick={() => setOpenPicker(!openPicker)}
                    // {...textFieldProps}
                  >
                    <CalendarMonthIcon />
                  </IconButton>
                ),
              }}
              {...otherProps}
            />
          </span>
          {strictValidString(errorText) &&
            required &&
            !disabled &&
            dateFocused && (
              <FormHelperText
                className={classes.helperText}
                id="component-helper-text"
              >
                {errorText}
              </FormHelperText>
            )}
        </LocalizationProvider>
      </div>
    );
  } else {
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDateTimePicker
            sx={{
              '& .MuiSvgIcon-fontSizeMedium': { display: 'none' },
            }}
            componentsProps={{
              actionBar: {
                // The actions will be the same between desktop and mobile
                actions: [clearText ? 'clear' : 'cancel'],
              },
            }}
            disableHighlightToday
            DialogProps={{
              onClose: () => {},
            }}
            // disablePast
            closeOnSelect={true}
            todayText="now"
            cancelText={null}
            showTodayButton={false}
            allowSameDateSelection={true}
            // clearText={true}
            allowKeyboardControl={true}
            toolbarTitle="Select Date and Time"
            inputFormat="MM/dd/yy HHmm"
            label={placeholder}
            value={datetime}
            onChange={(v) => {
              handleChange(v);
            }}
            onError={onError}
            minutesStep={5}
            components={{
              OpenPickerIcon: ClockIcon,
            }}
            onAccept={onSubmit}
            disabled={disabled}
            disableFuture={disableFuture}
            className={clsx(classes.withoutLabel, classes.textField)}
            maxDateTime={maxDateTime}
            maxDate={maxDate}
            minDateTime={minDateTime || null}
            minTime={minTime}
            InputProps={{ classes: { disabled: classes.disabledCheckbox } }}
            maxTime={maxTime}
            renderInput={(params) => (
              <>
                <TextField
                  onBlur={onBlur}
                  required={required}
                  id={id}
                  error={
                    strictValidString(errorText) ||
                    (required &&
                      !disabled &&
                      !strictValidString(params.inputProps.value))
                  }
                  {...params}
                />
              </>
            )}
            {...otherProps}
          />
          {strictValidString(errorText) && (
            <FormHelperText
              className={classes.helperText}
              id="component-helper-text"
            >
              {errorText}
            </FormHelperText>
          )}
        </LocalizationProvider>
        {deleteDialog()}
      </>
    );
  }
}
